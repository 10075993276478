import * as React from 'react';
import Layout from 'components/Layout';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <p>Not found</p>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
